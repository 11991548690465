<template>
  <div class="home-container">
    <div class="linear-bg"></div>
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v,key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular"/>
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <!-- <div class="notice-bar">
        <van-notice-bar
            class="notice-swipe"
            left-icon="bullhorn-o"
            background="#ffffff"
            color="#c24491"
            :text="this.notice"
        />
        <div class="linear-gradient"></div>
      </div> -->
      <div class="hot-game" style="padding-top: 10px;">
        
        <div class="hot-title-div">
          <van-field  v-model="search" style="width: 100%; font-size: 16px;" placeholder="Vui lòng nhập số..." /> <span class="fz" @click="searchn()" style="font-size: 16px;width: 100px;
  padding:6px 0;">Tìm kiếm</span>

          
        </div>

         
      
      </div>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" success-text="Success">
         <template #pulling>
          Cuộn xuống để Refresh
        </template>
        <template #loosing>
          Cuộn xuống để Refresh
        </template>
        <template #loading>
          Đang tải
        </template>
        <div class="hot-recommend">

<div class="model-item" v-for="(v,key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
  <div class="item-pic">
    <van-image class="van-image__img"  round :src="v.pic">
                <template v-slot:loading>
                  <van-loading type="circular"/>
                </template>
              </van-image>
  </div>
  <div  class="item-info">
    <div  class="name"><span ><b >{{v.label}}</b></span></div>
    <!---->
    <div  class="remark"><span >{{v.refuse}}</span></div>
    <div  class="call">
      <button  @click="gotoMenu('/Video')" class="van-button van-button--info van-button--normal van-button--round">
      <div  class="van-button__content"><span  class="van-button__text">Tôi muốn hẹn hò với cô ấy</span></div>
      </button>
    </div>
  </div>
</div>
         
        
         
         
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notice: "nhận......",
      banners: [{}],
      search: this.$route.query.search,
      basicData:[],
      gameitem: [{},{},{},{}],
      movielist_0: [{},{},{},{}],
      movielist_1: [{},{},{},{},{},{},{},{}],
      isLoading: false,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
      bannerSwiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        centeredSlides: true,
        slidesPerView: 'auto',
        speed:800,
        autoplay:true,
        coverflowEffect: {
          rotate: 50,
          stretch: 10,
          depth: 100,
          modifier: 1,
          slideShadows : true
        }
      }
    };
  },
  methods:{
    gotoMenu(router){
      this.$router.replace(router)
    },
    toLottery(key,id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/Lottery?key='+key+"&id="+id})
      }

    },
    searchn(){
    
     // this.$toast(this.search);
      this.$router.push({path:'/Choose?search='+this.search})
    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/profile?id='+id})
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast("Success");
      }, 500);
    },
    getVideoList(){
      this.$http({
        method: 'get',
        data:{page: 200},
        url: 'xuanfeilist'
      }).then(res=>{
        console.log(res.data.data)
         this.getMovieList_1(res.data.data);//获取首页视频1
      })
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res=>{
        this.basicData = res.data;
        console.log("3",res)
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
      })
    },
    
    getNotice(data){
      console.log('data===🚀===>', data)
      this.notice = data.notice;
    },
    getGameItem(){
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res=>{
        res.data.forEach((item, index) => {
          if (index === 0) {
          //  item.name = 'Bình Chọn 1'
          } else if (index === 1) {
          //  item.name = 'Bình Chọn 2'
          } else {
         //   item.name = 'Bình Chọn 3'
          }
        });
        this.gameitem = res.data
      })
    },
    getMovieList_0(data){
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data){
      this.movielist_1 = data
    },
    getBanner(data){
      this.banners = data.banners;
    }
  },
  mounted () {

  },
  created() {
    this.getBasicConfig();
    this.getVideoList()
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;
.folt {
  // overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: nowrap;
      width: 170px;
}
.linear-bg{
  height: 200px;
  background: linear-gradient(90deg,#775fd9,#c24491)
}
.home-container{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
}
.linear-gradient{
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}
::v-deep .van-notice-bar__left-icon, .van-notice-bar__right-icon {
  min-width:40px;
}
.notice-swipe{
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}
::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}
.banner{
  width: 100%;
  margin-top: -23%;
}
.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 300px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}
::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}
::v-deep .van-grid {
  justify-content: space-between;
}
.banner_img{
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.hot-game{
  width: 100%;
  height:  100%;
  background-color: #5731bc;
}
.hot-title-div{
  width: calc(100% - 50px);
  margin: 0 auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-title-div> div:first-child{
  width: 330px;
}
.hot-title-div div{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.hot-title-div>div:nth-child(2) span{
  font-size: 20px;
  color: #c24491;
}
.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #c24491;
}

.hot-title-div>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 28px;
  font-weight: 700;
  color: #c24491;
}
.hot-title-div > div:nth-child(2) span{
  font-size: 25px;
  color: #c24491;
}
.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 7px;
  height: 28px;
  background-color: #775fd9;
  border-radius: 1px;
  margin-right: 5px;
}
.hot-game .hot-items-div{
  margin-top: -3px;
}
.hot-game .hot-items-div span{
  margin-top: 10px;
  font-size: 28px;
  color: #c24491;
}
.hot-recommend{
  clear: both;
  width: 100%;
  flex: 1;
  background-color: #5731bc;
}
.movie_swiper{
  .swiper-slide {
    width: 80%;
  }
}
.movie_list_0{
  width: calc(100% - 50px);
  margin: 0 auto;
}
.movie_cover{
  border-radius: 10px;
  width:550px;
  height:330px
}
.movie_list_0 .movie-list-item-bottom{
  position: relative;
  width: 550px;
  bottom: 43px;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}
.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius:  0 0 10px 10px;
}
.movie_list_0 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}
.movie_list_0 span{
  font-size: 30px;
}
.movie_list_1{
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.movie_list_1 .movie-list-item .cover_img{
  border-radius: 10px;
  width:335px;
  height:320px;
}
.home-scroll{
  padding-bottom: 110px;
}
.movie_list_1 .movie-list-item{
  margin-bottom: -10px;
}

.movie_list_1 .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 42px;

}
.movie_list_1 .movie-list-item:nth-child(odd){
  margin-right: 25px;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}
.movie_list_1 .movie_swiper .hot{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right:110px;
  font-size: 22px;
}
.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more{
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #979799;
  font-size: 30px;
}
.hot-items-div .game_item_img{
  width: 100px;
  height: 100px;
}
::v-deep .hot-items-div .game_item_img .van-image__img{
  border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
 
}
.fz{
  font-size: 16px;
  color: #ffffff;
  background-color: #c24491;
  
  text-align: center;
  
   
  
}

.model-item {
    display: flex;
    position: relative;
    background-color: #8f65fe;
    border-radius: .5rem;
    margin: .625rem;
    padding: .625rem;
}
.model-item .item-pic{
    position: relative;
    width: 7.5rem;
    overflow: hidden;
    border-radius: 1.333vw;
    margin-right: .625rem;
}
.model-item .item-info{
    flex: 1;
    font-size: .75rem;
}
.model-item .item-info .name{
    margin-bottom: .625rem;
    color: #fff;
}
.model-item .item-info .remark{
    line-height: 1.6;
    margin-bottom: .75rem;
    display: -webkit-box;
    overflow: hidden;
    white-space: normal !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #fff;
}
.van-button--info
{
    border: none !important;
    background: linear-gradient(90deg, #a082f0, #ea92cf) !important;
    border-radius: .667vw !important;
}
.van-button--primary{
    border: none !important;
    background: linear-gradient(90deg, #a082f0, #ea92cf) !important;
    border-radius: .667vw !important;
}
.model-item .item-info .call .van-button{
    width: 100%;
    height: 2.125rem;
    border-radius: 6.1875rem !important;
    font-size: 22px;
}
.van-image--round {
    overflow: hidden;
    border-radius:0px;
    /* border-radius: 50%; */
}
.van-pull-refresh {
  background-color: #5731bc;
}


</style>
